.RankDashboard {
    padding: 0px 0px 0px 0px;
    margin-left: auto;

    .widgets {
        display: flex;
        margin-left: auto;
        align-content: flex-end;
        align-items: flex-end;
        justify-content: flex-end;
    }
}