.CompleteProjectDrawer {
    //display: none;  
    position: fixed;
    width: 460px;
    min-width: 460px;
    top: 0;
    right: 0px;
    height: 100%;
    background: #fff;
    //box-shadow: 0px 2px 4px 1px #ccc;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    overflow: scroll;

    opacity: 1 !important;
    transition: all .3s;

    background: rgb(238,191,248);
background: linear-gradient(0deg, rgba(238,191,248,1) 0%, rgba(255,217,173,1) 27%, rgba(255,217,173,1) 60%, rgba(255,159,46,1) 100%);

background: rgb(238,191,248);
background: linear-gradient(0deg, #c6ffcb 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, #ffecc6 100%);

    .big-heading {
        padding: 0.5em;
        padding-top: 0;
        font-size: 31pt;
        font-weight: 700;
        color: #111;
        text-align: center;
    }
    .big-subheading {
        padding: 1em;
        padding-top: 20px;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    .peak-img {
        width: 96px;
    }

    .rewards-subheading {
        padding: 1.25em 0 0 0;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    .rewards {
        display: flex;
        align-items: center;
        justify-content: center;

        .reward {
            width: 90px;
            min-width: 90px;
            height: 90px;
            margin: 20px;
            margin-bottom: 12px;
            border: 9px solid #eebff8;
            border-radius: 120px;

            .label {
                padding-top: 25px;
                color: #cb4ce5;
                font-weight: 500;
                font-size: 21pt;
            }
        }
        .rank-label {
            font-size: 13pt;
            font-weight: 500;
        }
        .rank-label-impact { color: darken(#ff9f2e, 8%);}
        .rank-label-growth { color: #13c828;}
        .rank-label-joy { color: #cb4ce5;}

        .reward-growth {
            border: 9px solid #97eda1;

            .label {
                color: #13c828;
            }
        }

        .reward-impact {
            border: 9px solid #ffd9ad;

            .label {
                color: darken(#ff9f2e, 8%);
            }
        }
    }

    .complete-button {
        cursor: pointer;
        width: 170px;
        margin: 50px auto;
        background: darken(#13c828, 4%);
        border-radius: 5px;
        padding: .5em 1em .6em 1em;
        font-size: 15pt;
        font-weight: 500;
        color: #fff;
    }
    .complete-button:hover {
        text-decoration: underline;
        background: darken(#13c828, 7%);
    }

    .title {
        padding: 110px 0 0 32px;
        font-size: 13.5pt;
        font-weight: 700;
        text-align: left;
    }

    .stats-row {
        padding: 32px 96px 0 32px;

        .heading {
            font-size: 10.5pt;
            text-align: left;
        }

        .stats {
            display: flex;
            margin: 16px 0;
            border: 1px solid #eee;
            border-radius: 8px;

            .stat {
                border-left: 1px solid #eee;
                width: 195px;
                min-width: 195px;
                padding: 20px 0;

                .number {
                    font-size: 24pt;
                    font-weight: 400;
                }
                .label {
                    padding-top: 12px;
                    font-size: 9.5pt;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }

            .stat:first-child {
                border-left: 0;
            }
        }
        .stats-rewards {
            width: 390px;
        }
    }

    .confirm-button {
        float: left;
        margin-left: 32px;
        margin-top: 22px;
        background-color: #1dce1d;;
        border-radius: 4px;
        padding: 0.5em 1em;
        font-size: 11.5pt;
        font-weight: 500;
        color: #fff;
    }
}

@media (max-height: 728px) {
    .CompleteProjectDrawer {
        .peak-img {
            width: 84px;
        }

        .complete-button {
            margin-top: 38px !important;
        }
    }
}