.RankWidget {
    //display: flex;
    // width: 28%;
    // min-width: 28%;

    //display: none;

    width: 100px;
    margin-top: 0px;
    margin-left: auto;

    .circle {
        width: 48px;
        min-width: 48px;
        margin: 0 auto;
    }

    .content {
        padding: 0px 0px 0px 0px;
        text-align: center;

        .label {
            padding-top: 4px;
            font-size: 10pt;
            font-weight: 500;
            text-align: center;
        }
        .rank {
            font-size: 10pt;
        }
    }

    .just-added-container {
        height: 27px;
        text-align: center;
    }
    .just-added {
        height: 27px;
        text-align: center;
        padding-top: 3px;
    }
    .just-added-label {
        padding: 2px;
        padding-top: 2px;
        text-align: center;
    }
}