.ProjectGem {
    display: flex;
    padding-left: 12px;

    .icon {
        padding-top: 22px;

        .icon-img {
            width: 24px;
        }
    }

    .notch-bar {
        display: flex;
        width: 160px;
        min-width: 160px;
        margin-top: 4px;
        //zoom: 0.9;
    }

    .fill-position {
        position: relative;
        width: 80px;
        height: 80px;
    }
    .fill-bar {
        position: absolute;
        top: 0.5px; 
        left: -160px;
        width: 70px;
        height: 80px;
        //background-color: #eee;
        background-size: 2px 19px;
        background-repeat: repeat-x;
        background-position: 0px 24px;
    }
    .fill-notch {
        position: absolute;
        top: 24.5px; 
        left: -90px;
        width: 18px;
        align-self: center;
    }

    .session-position {
        position: relative;

        .session {
            position: absolute;
            top: 42px;
            left: -166px;
            width: 140px;
            //background-color: #eee;
        }

        .time-label {
            padding-top: 8px;
            font-weight: 400;
            font-size: 11.5pt;
        }
    }

    .done-content {
        display: flex;
        width: 180px;
        padding-top: 8px;
        //margin-right: 22px;
        //background-color: #ddd;

        .completed-label {
            padding: 9px 8px 0 0;
        }

        .done-button {
            padding: 10px 20px;
            background-color: #1dce1d;
            border: 1px solid #fff;
            border-top: 0;
            border-right: 0;
            color: #fff;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            transition: .7s;
        }
    }
}