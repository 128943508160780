.Mast {
    display: flex;
    align-items: flex-start;

    .logo {
        display: flex;
        padding: 2px 12px 0px 12px;
        font-size: 14pt;
        font-weight: 700;
        letter-spacing: -0.5px;
        position: fixed;  
        width: 100%;
        background: #fff;  
        box-shadow: 1px 1px 2px 1px darken(#f7ece2, 0%);
        text-align: left;
    }

    .logo-img {
        width: 56px;
        opacity: 1;
        align-self: center;
    }

    .tools {
        margin-left: auto;
        margin-top: 16px;
        margin-right: 36px;

        .settings {
            .icon-img {
                width: 26px;
                opacity: 0.5;
            }
        }
    }

    .beta {
        margin: 16px;
        margin-left: 4px;
        padding: 4px 8px;
        border-radius: 10px;
        background-color: #ffe2c0;
        color: darken(#ffe2c0, 70%);
        font-size: 9pt;
        font-weight: 500;
    }
}