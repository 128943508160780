$notchWidth: 27px;
$circleWidth: 12px;

.GemNotch {
    display: flex;
    margin-top: 24px;

    .knob {
        margin-top: 4px;
        width: $notchWidth - $circleWidth;
        min-width: $notchWidth - $circleWidth;
        height: 3px;
        background-color: lighten(#97eda1, 15%);
        border-bottom: 1px solid lighten(#97eda1, 15%);
    }

    .notch {
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: lighten(#97eda1, 15%);
        border-bottom: 1px solid lighten(#97eda1, 15%);
    }
}

$completedColor: lighten(#ffe49b, 1%);
$overPendingColor: darken(#97eda1, 9%);

.GemNotch.Completed {
    .knob {
        background-color: $completedColor;
        border-bottom: 1px solid $completedColor;
    }
    
    .notch {
        background-color: $completedColor;
        border-bottom: 1px solid $completedColor;
        opacity: 1;
    }
}

.GemNotch.is-touched {
    .knob {
        background-color: $overPendingColor;
        border-bottom: 1px solid $overPendingColor;
    }

    .notch {
        background-color: $overPendingColor;
        border-bottom: 1px solid $overPendingColor;
        opacity: 1;
    }  
}

.GemNotch.is-touched-end {
    .knob {
        background-color: $overPendingColor;
        border-bottom: 1px solid $overPendingColor;

        // width: 6px;
        // min-width: 6px;
    }

    .notch {
        background-color: #fff;
        border: 3px solid darken($overPendingColor, 20%);
        opacity: 1;

        margin-top: 0px;
        width: 7px;
        min-width: 7px;
        height: 7px;
        border-radius: 8px;
    }
}