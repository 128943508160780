.BlankHealthCard {
    display: flex;
    height: 69px;
    margin-top: 6px;
    margin-bottom: 4px;
    margin-left: 0px;
    border-radius: 100px;
    padding-right: 8px; 

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 51px;
        margin-top: 9px;
        margin-left: 18px;
        border: 2px dashed #ddd;
        border-radius: 100px;

        .icon-img {
            width: 32px;
            opacity: 0.3;
        }

        .label {
            padding-left: 4px;
            font-size: 9.5pt;
            font-weight: 700;
            text-transform: uppercase;
            color: #111;
            opacity: 0.9;
        }
    }
}