.HealthCard {
    display: flex;
    height: 71px;
    margin-top: 8px;
    margin-left: 0px;
    border-radius: 100px;
    padding-right: 8px;

    .icon {
        min-width: 40px;
        padding: 10px 0 0 16px;

        .icon-img {
            width: 36px;
            
        }
    }
    .content {
        padding: 14px 0px 0 10px;
    }
    
    .health-label {
        font-size: 13.5pt;
        font-weight: 500;
        color: #111;
        text-align: left;
    }

    .health-since {
        font-size: 10pt;
        font-weight: 400;
        color: #111;
        text-align: left;
    }

    .health-progress-bar {
        margin-top: 6px;
        width: 78px;
        height: 4px;
        border-radius: 14px;
        background-color: lighten(#ffd9ad, 7%);
    }
    .health-progress-fill {
        width: 22px;
        height: 4px;
        border-radius: 14px;
        background-color: lighten(#ff9f2e, 19%);
        transition: 1s width;
    }
}