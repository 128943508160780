.BonusItem {
    margin-bottom: 0px;

            .item-img {
                width: 28px;
            }
            .shadow-img {
                position: absolute;
                margin-top: 30px;
                margin-left: -28px;
                width: 28px;
                opacity: 0.7;
            }
            .item-label {
                margin-top: -2px;
                font-size: 10pt;
                font-weight: 400;
                display: none;
            }
}

.BonusItem.ruby12 {
    height: 40px;

    .item-img {
        width: 28px;
    }
    .item-label {
        margin-top: -6px;
    }
}
.BonusItem.ruby11 {
    height: 40px;

    .item-img {
        width: 25px;
    }
    .item-label {
        margin-top: -6px;
    }
}
.BonusItem.ruby20 {
    height: 38px;

    .item-img {
        width: 28px;
    }
    .shadow-img {
        margin-top: 16px;
        margin-left: -25px;
    }
    .item-label {
        margin-top: -6px;
    }
}
.BonusItem.gem-green {
    height: 48px;

    .item-img {
        width: 28px;
    }
    .shadow-img {
        margin-top: 21px;
        margin-left: -29px;
    }
    .item-label {
        margin-top: -6px;
    }
}
.BonusItem.is-dragging {
    opacity: 0.3;
}