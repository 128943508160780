.ChooseThought {

    .heading {
        margin-top: -2px;
        margin-bottom: 2px;
        padding-left: 0px;
        //text-transform: uppercase;
        font-size: 10.5pt;
        font-weight: 600;
        //letter-spacing: 1px;
        text-align: left;

        transition: opacity 1s, height 0.35s;
        transition-timing-function: ease-out;
    }

    .options {
        padding: 0px 0 18px 0;
    }
}