.SettingsDrawer {
    //display: none;  
    position: fixed;
    width: 460px;
    min-width: 460px;
    top: 0;
    right: 0px;
    height: 100%;
    background: #fff;
    //box-shadow: 0px 2px 4px 1px #ccc;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    overflow: scroll;

    opacity: 1 !important;
    transition: all .3s;

    background-color: #fff;
    
    .big-heading {
        padding: 0.5em;
        padding-top: 0;
        font-size: 31pt;
        font-weight: 700;
        color: #111;
        text-align: center;
    }
    .big-subheading {
        padding: 1em;
        padding-top: 20px;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .heading {
        font-size: 17.5pt;
        font-weight: 700;

    }
    
    .options {
        padding: 22px 0;
    }

    .item {
        text-align: left;
        padding: 16px 32px;
        border-bottom: 1px solid #eee;
        font-size: 15pt;
        color: #333;
    }
}

@media (max-height: 728px) {
    .CompleteProjectDrawer {
        .peak-img {
            width: 84px;
        }

        .complete-button {
            margin-top: 38px !important;
        }
    }
}