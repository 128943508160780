.ProjectStub {
    width: 194px;
    height: 78px;
    background-color: lighten(#fff2b4, 2%);
    background-color: #fff;
    border: 0px solid darken(#eee, 2%);
    border-bottom: 0;
    margin: 0 2.5px;
    margin-top: 5px;
    //border-top: 0;


    border-radius: 12px 12px;

    box-shadow: 1px 1px 3px 1px darken(#f7ece2, 4%);

    // border: 1px solid darken(#eee, 4%);
    // border-top: 1px solid darken(#eee, 0%);
    // border-bottom: 1px solid darken(#eee, 8%);
    // border-right: 1px solid darken(#eee, 4%);
//    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 4%);

    .title {
        padding: 8px 16px;
        text-align: left;
        font-size: 11.5pt;
        font-weight: 500;

        .icon-img {
            width: 18px;
        }
    }

    .sessions {
        display: flex;
        padding-left: 16px;

        .session {
            width: 74px;
            border-bottom: 1px solid #ccc;
            margin-right: 8px;
            display: flex;

            .time-box {
                width: 10px;
                height: 10px;
                margin: 6px 8px 0 0;
                //border: 1px solid darken(#FFFCF9, 28%);
                //border: 1px solid #555;
                //border: 1px solid #ccc;
                border-radius: 3px;
        
                border: 2px solid darken(#ffd9ad, 15%);
                //background: lighten(#ffd9ad, 10%);
                //border: 2px solid darken(#78e184, 3%);
            }
            .time-label {
                padding: 3px 0;
                text-align: left;
                font-size: 12pt;
                font-weight: 500;
                color: #333;
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
            }

            .check-img {
                margin: 6px 6px 0 -1px;
                width: 14px;
                height: 14px;
            }
        
            .time-label {
                font-weight: 500;
                color: darken(#25c253, 0%);
                color: #888;
                padding: 4px 0;
                //text-decoration: line-through;
            }
        
            .strike {
                color: #888; text-decoration: line-through;
            }
            .strike-inner {
                color: #888;
            }
        }

        .session.Completed {
            border-bottom: 0;
        }

        .session.Growth {
            .time-box {
                border: 2px solid darken(#78e184, 3%);
            }
        }
    }
}