.HealthGrid {
    padding-top: 20px;
    padding-right: 0px;

    .tier {
        display: flex;
        justify-content: flex-start;
        padding-top: 16px;
        padding-bottom: 24px;
        padding-right: 0px;
        border-bottom: 1px solid #eee;

        padding-left: 22px;
    }
    .tier:nth-child(even) {
        //padding-left: 0px !important;
    }
    .tier:last-child {
        border-bottom: 0;
    }

    .tier-options {
        width: 500px;
        min-width: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tier-label {
        // width: 100px;
        // min-width: 100px;
        width: 138px;
        min-width: 138px;
        margin-top: 10px;
        margin-right: 20px;
        
        align-self: center;
        justify-self: flex-start;

    }
    .tier-label-badge {
        float: left;    
        font-size: 11.5pt;
        font-weight: 600;
        color: #fff;
        text-align: center;
        border-radius: 100px;
        //margin-right: auto;

        padding: 0.8em 1.2em;
        text-transform: uppercase;
        font-size: 10.5pt;
        font-weight: 700;
    }

    .tier-label-end {
        background-color: lighten(#ffd9ad, 7%);
        color: darken(#ffd9ad, 35%);
    }
    .tier-label-mid {
        background-color: lighten(#ffe577, 13%);
        color: darken(#ffe577, 34%);
    }
    .tier-label-early {
        background-color: lighten(#97eda1, 11%);
        color: darken(#97eda1, 35%);
    }

    .controls {
        width: 480px;
        display: flex;
        margin: 20px auto;
    }

    .selected-option {
        display: flex;
        //width: 228px;
        height: 71px;
        margin-top: 0px;
        margin-left: 0px;
        border-radius: 12px;
        padding-right: 30px;
        padding-bottom: 8px;
    
        align-items: center;
        justify-content: center;
    
        //transform: scale(0.95);
    
        .icon {
            min-width: 66px;
            padding: 13px 0 0 16px;
    
            .icon-img {
                width: 66px;
            }
        }
        .content {
            padding: 6px 0px 0 6px;
        }
        
        .health-label {
            font-size: 14.5pt;
            font-weight: 700;
            color: #111;
            text-align: left;
        }
    
        .health-since {
            display: none;
            font-size: 10pt;
            font-weight: 400;
            color: #111;
            text-align: left;
        }
    }

    .select-button {
        width: 120px;
        min-width: 120px;
        margin-top: 4px;
        margin-left: auto;

        background: darken(#13c828, 4%);
        border-radius: 5px;
        padding: .5em 1em .6em 1em;
        font-size: 15pt;
        font-weight: 500;
        color: #fff;
    }

    .select-button:hover {
        text-decoration: underline;
        background: darken(#13c828, 7%);
    }
    
    .selected-more {
        padding-top: 8px;
        padding-left: 32px;
        font-size: 13pt;
        font-weight: 400;
        color: #111;
        line-height: 1.35em;
        text-align: left;
    }

    .disable-message {
        padding-top: 4px;
        line-height: 1.3em;
        padding-left: 30px;
        font-size: 11.5pt;
        color: darken(#ffd9ad, 40%);
    }
}