.BonusBar {
    display: none;
    padding: 32px 0px;

    transform: scale(.8);
    //opacity: 0.5;

    transition: all .2s ease-in-out;
    
    .items {
    }
}

.BonusBar:hover {
    transform: none;
    padding-top: 34px;
}