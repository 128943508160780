@media (max-width: 720px) {

    .react-tiny-popover-container {
        display: none !important;
        opacity: 0 !important;
    }

    .App {

        .Mast {
            .logo {
                position: static !important;
                padding: 20px 26px;
            }
            
        }

        .ProjectWin {
            //padding-left: 16px !important;
        }

        .ProjectsScreen {

            .projects-col {
                float: none;
                width: 96%;
                margin-left: 2%;

                .projects-head {
                    padding-top: 0px !important;
                }

                .projects-heading {
                    padding-top: 0px !important;
                    //padding-left: 26px;
                }
            }

            .draggable-col {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                justify-content: flex-end;
                float: none;
                position: fixed;
                width: 100%;
                height: 90px;
                border-top: 1px solid darken(#FFFCF9, 10%);
                background-color: #FFFCF9;

                left: 0px;
                bottom: 0px;

                margin-top: 0 !important;
            }

            .DraggableCoin-container {
                .just-added-container {
                    margin-top: 0 !important;
                    height: 18px !important;
                }
            }

            .RankDashboard {
                .widgets {
                    display: flex;
                    padding-right: 12px;
                    margin-top: -20px !important;
                }
                
                .RankWidget {
                    width: 74px;
                    margin-top: 4px !important;
                }
            }
        }
    }

}