.NewProjectCard {
    width: 433px;
    //min-height: 438px;
    height: 360px;
    background-color: transparent;
    margin: 6px 6px;
    margin-bottom: 0;
    border: 0px solid darken(#FFFCF9, 5%);
    border-radius: 4px;
    padding-bottom: 0px;
    //box-shadow: 0px 2px 4px 1px #eee;
    //display: none;
    zoom: 0.95;

    .heading {
        display: flex;
        padding: 22px;
        text-align: left;
        font-size: 13pt;
        font-weight: 700;
        color: darken(#FFFCF9, 35%);
        color: #444;
    }
    .heading-label {
        padding-left: 10px;
        padding-top: 5.5px;
    }

    .requirement {
        padding: 0 22px;
        text-align: left;
        font-size: 10.5pt;
        font-weight: 400;
        color: darken(#FFFCF9, 35%);
        color: #777;
    }

    .plus-button {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        background: #fff;
        box-shadow: 1px 2px 4px 1px #ddd;
        transition: width .3s;
    }
    .plus-button-img {
        width: 14px;
        margin: 9px 0 0 9px;    
        opacity: 0.5;
    }

    .card-form {
        padding: 22px;
        padding-top: 0;
        text-align: left;
    }
    .field-heading {
        text-align: left;
        font-size: 11pt;
        font-weight: 400;
    }
    .field-text {
        width: 360px;
        margin-bottom: 22px;
        padding: 10px 0 4px 0;
        border: 0;
        border-bottom: 1px solid #ddd;

        color: #111;
        font-size: 20.5pt;
        font-weight: 700;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

        outline: none;
    }

    .field-choose-stage {
        display: flex;
        padding: 12px 20px 0 0;
    }

    .field-submit-button {
        cursor: pointer;
        float: right;
        margin-top: 0px;
        margin-right: 32px;
        background-color: #ff9833;
        border-radius: 4px;
        padding: 0.6em 1em 0.7em 1em;
        font-size: 12.5pt;
        font-weight: 500;
        color: #fff;
    }
    .field-submit-button:hover {
        //text-decoration: underline;
        background-color: darken(#ff9833, 9%);
        text-decoration: underline;
        cursor: pointer;
    }
}

.NewProjectCard.is-editing {
    background-color: #fff;
    // border-top: 1px solid darken(#f7ece2, 2%);
    border: 1px solid darken(#eee, 4%);
    border-top: 1px solid darken(#eee, 0%);
    border-bottom: 1px solid darken(#eee, 8%);
    border-right: 1px solid darken(#eee, 4%);
    border-radius: 3px;
    padding: 0px;
    padding-bottom: 2px;
    padding-left: 6px;
    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 4%);

    .heading {
        padding-bottom: 0;

        .plus-button {
            opacity: 0;
            width: 0 !important;
        }
        .heading-label {
            padding-left: 0;
            color: #111;
        }
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ccc;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ccc;
  }