.ProjectCard {
    width: 438px;
    //height: 540px;
    background-color: #fff;
    margin: 20px 10px;
    margin-bottom: 0;
    border: 1px solid darken(#eee, 4%);
    border-top: 1px solid darken(#eee, 0%);
    border-bottom: 1px solid darken(#eee, 8%);
    border-right: 1px solid darken(#eee, 4%);
    // border-left: 1px solid darken(#f7ece2, 2%);
    // border-top: 1px solid darken(#f7ece2, 2%);
    border-radius: 3px;
    padding: 0px;
    padding-bottom: 22px;
    //padding-top: 4px;
    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 2%);
    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 4%);
    //box-shadow: 0px 2px 3px 1px #eee;

    //margin: 10px 10px;
    //margin: 5px;

    // border-radius: 1px;
    // border: 1px solid #ccc;
    margin: 7px 7px;

    zoom: .95;

    //border-radius: 12px 12px;

    //box-shadow: 1px 1px 3px 1px darken(#f7ece2, 4%);

    // box-shadow: none;

    // margin: 0;
    // border: 0;
    // box-shadow: none;
    // padding: 19px;
    // padding-bottom: 0px;
    // border-right: 1px solid #ddd;
    //border-bottom: 1px solid #ddd;
    //padding: 40px;

    .head {
        display: flex;

        .title {
            padding: 1em;
            padding-left: 28px;
            padding-bottom: 0;
            color: #111;
            font-size: 20.5pt;
            font-weight: 700;
            text-align: left;
        }

        .tools {
            margin-left: auto;
            padding: 15px 18px 0 0; 
        }
    
    }

    .section-heading {
        padding-left: 22px;
        text-align: left;
        font-size: 11.5pt;
        font-weight: 400;
        color: #111;
    }

    .project-total {
        padding: 4px 32px 40px 36px;
        font-weight: 500;
        font-size: 13pt;
        text-align: left;
        color: #fff;

        .sessions-count {
            font-size: 10.5pt;
            color: #fff;
        }
    }

    .todays-sessions {
        min-height: 156px;
        margin-top: 0px;
        padding-top: 8px;
        padding-bottom: 2px;
        border-top: 0px solid #e3e3e3;

        border-top: 0px solid #e3e3e3;

        .section-checkmark-img { margin-left: 0px;     width: 13px; }
        .section-checkmark-img:nth-child(2) { margin-left: 0px; margin-right: 2px; opacity: 1; }

        .section-heading {
            padding-bottom: 9px;
            padding-left: 0 !important;
            margin: -1px 30px 1px 35px;
            //opacity: 0;
        }

        .sessions-content {
            display: flex;
            padding-right: 22px;

            .daily-sessions-content {
                flex: 1;
            }

            .ruby-session-content {
                flex: 0;
                min-width: 100px;

                display: none;  

                margin: 7px 0 8px 8px;

                border: 1px solid #999;
                border-radius: 4px;

                //opacity: .5;

                .ruby-item {
                    margin-top: 18px;

                    .item-img {
                        width: 34px;
                    }

                    .item-label {
                        padding-top: 2px;
                        font-weight: 500;
                        font-size: 11pt;
                        color: #111;
                    }
                }
            }

            .done-session-content {
                display:none;
                flex: 0;
                min-width: 100px;

                margin: 7px 0 8px 8px;

                border: 1px solid #fff;
                border-radius: 4px;

                .ruby-item {
                    margin-top: 10px;

                    .item-img {
                        width: 31px;
                    }

                    .item-number {
                        padding-top: 7px;
                        font-weight: 500;
                        font-size: 11.5pt;
                        color: #111;
                        //text-transform: uppercase;
                    }

                    .item-label {
                        padding-top: 2px;
                        font-weight: 500;
                        font-size: 9.5pt;
                        color: #111;
                        //text-transform: uppercase;
                    }
                }
            }
        }
    }

    .win-count {
        float: left;
        width: 19px;
        height: 19px;
        border-radius: 8px;
        background: lighten(#eebff8, 5%);
        background: darken(#fdf6fe, 4%);
        background: lighten(#ffe5c7, 6%);
        margin: -2px 2px 0 21px;
        padding: 3px 0 0 4.5px;
        box-sizing: border-box;
        font-size:10.5pt;
        font-weight: 600;
        color: darken(#cb4ce5, 5%);
        color: darken(#ff9f2e, 10%);
        color: #111;

        background: #fff;
        border: 0px solid #ffb56a;
    }
    .minutes-count {
        font-size: 10pt;
        font-weight: 600;
        color: #111;
    }
    .minutes-dot { color: #555; }

    .project-details {
        display: none;
        .section-heading {
            padding-bottom: 2px !important;
        }
        .project-col {
            float: left;
            width: 100%;
            padding-bottom: 18px;
        }
        .project-col-momentum {
            display: none;
            margin-top: 8px;
            border-top: 1px solid #e3e3e3;
            padding-top: 4px;
        }
    }
}
.ProjectCard.done-today {
    .todays-sessions {
        .section-heading {
            //color: lighten(#25c253, 3%) !important;
            color: #111;
            padding-bottom: 5.5px !important;
            //text-decoration: line-through;
        }
        .section-total-time {
            font-weight: 700;
            font-size: 10pt;
        }
        .section-total-bull {
            padding-right: 4px;
        }
        .section-flex-img {
            width: 14px;
            margin-left: 4px;
        }
    }

    .done-session-content {
        //display: block !important;
    }

    .sessions-completed-border {
        height: 1px;
        margin-left: 28px;
        margin-top: -9px;
        background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(220,220,200,1) 50%, rgba(220,220,220,1) 95%, rgba(255,255,255,1) 100%);
        //background: #eee;
    }
}

.ProjectCard.bonus-is-over {
    border: 1px solid #f4d434;
}

.HealthJourney {
    display: flex;
    padding-left: 14px;

    .current-card {
        min-width: 190px;
    }

    .gem-container {
        width: 200px;
        padding-left: 20px;
        
    }

    .arrow {
        padding-top: 32px;

        .arrow-img {
            width: 36px;
           // opacity: 0.1;
        }
    }
    
    .goal-card {
        min-width: 180px;
    }
}

.ProjectWins {
    height: 91px;
    overflow: hidden;
}

.ProjectMomentum {
    display: flex;
    padding-top: 8px;
    padding-left: 22px;

    .icon-img {
        margin: 2px 4px 0 0;
        width: 5%;
    }

    .grade {
        font-size: 15pt;
        font-weight: 600;
        color: #111;
    }

    .metric {
        margin-top: 2px;
        margin-left: 8px;
        border-radius: 100px;
        padding: 2px 12px 4px 12px;
        font-size: 11pt;
    }
    .metric-sessions { background-color: #FFEFD8; }
    .metric-minutes { background-color: #D1EFFF; }
    .metric-wins { background-color: #D7FFD5; }
}

.estimate {
    padding-top: 6px;
    padding-left: 22px;
    font-size: 10pt;
    text-align: left;
}
.estimate-number {
    font-weight: 600;
}


.project-grades {

}

.ProjectGrade {
    padding-left: 14px;
    padding-top: 32px;
    padding-bottom: 0px;

    .heading {
        font-size: 11.5pt;
        font-weight: 500;
        color: #333;
        text-align: left;
    }

    .legend {
        display: flex;
        margin-top: -22px;
    }

    .legend-icon {
    }
    .legend-icon-check {
        margin-left: 140px;
    }
    .legend-icon-coin {
        margin-left: 58px;
    }

    .legend-icon-img {
        width: 18px;
        opacity: 0.3;
        margin-top: 1px;
        align-self: center;
    }

    .progress-bar {
        margin-top: 2px;
        width: 238px;
        height: 3px;
        border-radius: 14px;
        background-color: lighten(#ffd9ad, 12%);
    }
    .progress-fill {
        width: 22px;
        height: 3px;
        border-radius: 14px;
        background-color: lighten(#ff9f2e, 22%);
        background: linear-gradient(90deg, rgba(255,211,158,1) 0%, rgba(255,176,81,1) 100%);
        transition: 1s width;
        opacity: 0.6;
    }

    .time-box {
        width: 10px;
        height: 10px;
        margin: 6px 8px 0 0;
        //border: 1px solid darken(#FFFCF9, 28%);
        //border: 1px solid #555;
        //border: 1px solid #ccc;
        border-radius: 3px;

        border: 2px solid darken(#ffd9ad, 15%);
        opacity: 0.5;
        //background: lighten(#ffd9ad, 10%);
        //border: 2px solid darken(#78e184, 3%);
    }
}

.all-time-content {
    padding-top: 20px;
}

.all-time-day {
    padding-bottom: 4px;
    padding-left: 30px;
    font-size: 10.5pt;
    font-weight: 500;
    color: #555;
    text-align: left;
}

.all-time-tabs {
    display: flex;
    padding-left: 30px;

    .tab {
        display: flex;
        padding-right: 12px;

        .value {
            font-size: 11.5pt;
            color: #555;
            font-weight: 500;
        }

        .label {
            padding: 3px 0 0 2px;
            font-size: 9pt;
            text-transform: uppercase;
            color: #888;
        }
    }
}

.ProjectGrade.Confidence {
    .progress-bar {
        background-color: lighten(#e7ffe2, 0%);
    }
    .progress-fill {
        background-color: lighten(#e7ffe2, 28%);
        background: linear-gradient(90deg, rgba(231,255,226,1) 0%, rgba(174,255,170,1) 100%);
        opacity: 0.9;
    }

    .time-box {
        border: 2px solid darken(#78e184, 3%);
    }
}

.today-project-widget {
    .circle-done {
        margin-top: 1px;
        width: 42px;
    }
    .done-img {
        margin: 13.5px 12px 0 0;
        width:24px;
        opacity: 0.75;
    }
}

.clearfix { clear: both; }