.ProjectWin {
    padding: 6px 0px 0px 22px;
    display: flex;
    align-items: flex-start;
    
    .icon-img {
        width: 16px;
    }

    .label {
        display: flex;
        border: 2px solid lighten(#22B14C, 46%);
        border-radius: 40px;
        background-color: lighten(#22B14C, 57.5%);
        padding: 9px 16px;
        padding-top: 7px;
        font-size: 10pt;
        font-weight: 500;
        text-align: left;
        color: lighten(#22B14C, 4%);
        color: #111;

        //background-color: #fff !important;

        // background-color: #fff !important;
        // border: 0 !important;
    }
    .label-text {
        padding-top: 1px;
        padding-left: 4px;
    }
    .label-old {
       //opacity: 0.7;
       //font-weight: 400;
    }

    .label-growth {
    }
    .label-impact {
        border: 2px solid lighten(#ffd9ad, 6%);
        background-color: lighten(#ffd9ad, 15%);
    }
    .label-joy {
        border: 2px solid lighten(#eab2f6, 9%);
        background-color: lighten(#eab2f6, 16%);
    }

    .ago {
        padding: 12px 0 0 6px;
        font-size: 10pt;
        font-weight: 400;
        color: #888;
    }
    .ago-length {
        font-weight: 600;
    }
}