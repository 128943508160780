.ProjectsScreen {
    width: 100%;

    .main-layout {
        margin: 0 auto;
        max-width: 100%;
        //background-color: #fff;

        padding-left: 0px;

        display: flex;
    }

    .draggable-col {
        //opacity: 0;
        flex: 1;
        max-width: 240px;
        min-width: 90px;
        //background-color: #fff;
        //background-color: darken(#fffdf9, 2.5%);
        //border-bottom: 1.5px solid darken(#eee, 2%);
        //box-shadow: 0px 0px 3px 1px darken(#eee, 2%);

        padding-top: 134px;
        padding-left: 0px;
        box-sizing: border-box;

        // background-color: lighten(#97eda1, 22%);
        // border-bottom: 1.5px solid darken(#eee, 2%);
        // box-shadow: 0px 0px 3px 1px lighten(#97eda1, 8%);

        //background-color: #fff;
        zoom: .92;

        .projects-heading {
            opacity: 0;
            padding: 0px 20px 12px 16px;
            font-size: 11pt;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
            //opacity: 0.55;
            text-align: right;
        }

        .draggable-fixed {
            //width: 100%;
            width:inherit;
            max-width:inherit;
            min-width:inherit;
            position: fixed;

            top: 190px;
            right: 172px;
        }
    }
    .projects-col {
        //float: left;
        width: 948px;
        min-width: 898px;
        flex: 0;
        margin: 0;
        //margin-left: 106px;
    }
    .bonus-col {
        //float: left;
        width: 52px;
        height: 400px;
        margin-left: 1008px;
        margin-top: 160px;
        position: fixed;
    }
    .activity-col {
        flex: 1;
        //position: fixed;
        //width: 400px;
        min-width: 280px;
        //max-width: 340px;
        min-height: 97vh;
        background-color: darken(#fffcf9, 2.5%);
        border-bottom: 1.5px solid darken(#eee, 2%);
        box-shadow: 0px 0px 3px 1px darken(#fffcf9, 12%);

        zoom: 0.95;

        padding-top: 78px;
        padding-left: 8px;
        text-align: left;

        .projects-heading {
            padding: 40px 0 12px 16px;
            font-size: 11pt;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
            //opacity: 0.55;
            text-align: left;
        }
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;


        padding-top: 12px;
        padding-bottom: 90px;

        padding-right: 0px;

        // background: #fff;
        // padding: 20px;
        // border: 1px solid #ccc;
        // box-shadow: 0px 2px 3px 1px #eee;
    }

    .projects-head {
        display: flex;
        padding-top: 20px;

        .projects-heading {
            padding: 90px 0 0 16px;
            font-size: 11pt;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
            //opacity: 0.55;
        }

        .ranks-dashboard {
            margin-left: auto;
            padding-right: 40px;
        }
    }
}

.DayIcon {
    display: flex;
    width: 200px;
    height: 40px;
    padding: 8px 12px;
    position: fixed;
    top: 4px;
    left: 60px;
    opacity: 1;

    .morning-img {
        width: 24px;
        align-self: center;
    }
    .label {
        padding: 11px 2px 2px 6px;
        font-size: 9.5pt;
        font-weight: 600;
        color: #777;
    }
}

.activity-widget {
    display: flex;

    background-color: #fff;
    border-radius: 12px;
    margin-top: 12px;
    margin-left: 4px;
    padding: 4px 16px 4px 4px;

    zoom: 1.2;


    box-shadow: 1px 1px 3px 1px darken(#f7ece2, 4%);
}

.day-heading {
    padding-top: 44px !important;
}

.progress-heading {
    padding-top: 0;
}

.ranks-widget {
    width: 230px;
    margin-top: 0px;
    margin-left: 2px;
    background-color: darken(#fffcf9, 0.5%);
    border: 2px solid darken(#fffcf9, 5.5%);
    border-radius: 20px;

    .widget-content {
        zoom: 0.85;
        padding: 0 8px 26px 8px;
    }
}
.coin-widget {
    width: 84px;
    margin-top: 12px;
    padding-left: 6px;
}

.today-widget {
    display: inline-flex;
    align-self: flex-start;
    align-items: flex-start;
    //margin-right: 212px;

    background-color: #fff;
    border-radius: 12px;
    
    height: 50px;
    margin-top: 0px;
    margin-left: 4px;
    padding: 4px 16px 8px 4px;

    zoom: 1.1;


    box-shadow: 1px 1px 3px 1px darken(#f7ece2, 4%);

    .peak-img {
        width: 36px;
        align-self: center;
    }

    .circle {
        width: 36px;
        min-width: 36px;
        max-width: 36px;
        margin: 0 14px 0 16px;
        margin-top: 10px;
    }

    .minutes {
        flex: 1;
        padding: 16px 6px 0 0px;
        margin-left: -4px;
        font-weight: 500;
        font-size: 13pt;
        text-align: left;
    }

    .minutes-total {
        //display: none;
        color: lighten(#67d171, 7%);
        color: #aaa;
        font-size: 12.5pt;
    }
}

.today-widget-only {
    .circle-done {
        margin-top: 1px;
        width: 66px;
    }
    .done-img {
        margin: 7.5px 18px 0 16px;
        width: 36px;
        opacity: 1;
    }
}

.today-widget.today-project-widget {
    zoom: 1;

    .circle {
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        margin: 15px 14px 0 0px;
    }
}

.project-goal {
    display: flex;
    padding-right: 12px;

    .today-label {
        flex: 1;
        margin-left: auto;
        padding-top: 23.5px;
        padding-right: 14px;
        font-size: 9pt;
        text-transform: uppercase;
        color: #888;
        text-align: right;
    }
}
.project-goal .today-widget {
    //min-width: 132px;
    margin-left: auto;
    box-shadow: none !important;
    padding-left: 0;
    zoom: 0.95;
    text-align: right;

    justify-items: flex-start;
}

.projects-heading {
    color: #9d917b;
}

.clear { clear: both; }

@media (max-width: 1350px) {
    .ProjectsScreen {
        .draggable-col {
            //width: 200px !important;
        }
    } 
}