.HealthGridDrawer {
    //display: none;
    position: fixed;
    width: 48%;
    min-width: 720px;
    top: 0;
    right: 0px;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 4px 1px #ccc;

    .title {
        padding-top: 70px;
        font-size: 11pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .project-title {
        padding: 1em;
        padding-top: 20px;
        padding-bottom: 0;
        color: #111;
        font-size: 27pt;
        font-weight:700;
        text-align: center;
    }
}