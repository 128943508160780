body {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: darken(#fffcf9, 0%);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently */
}
// body.dragging-plus {
//   //background: #ddd !important;

//   .ProjectCard {
//     width: 360px !important;
//     height: 280px !important;

//     .project-details {
//       display: none;
//     }
//   }
// }

.App {
  text-align: center;
  //background-color: #FFFCF9;
  height: 100%;
  //min-height: 2000px;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Ruby {
  display: flex;
  //align-items: center;
  //display: none;

  .coin {
    // background-color: darken(#fffcf9, 2.5%);
    // background-color: #fff;
    // border-bottom: 1.5px solid darken(#eee, 2%);
    // box-shadow: 0px 0px 3px 1px darken(#fffcf9, 12%);

    padding: 6px 12px;
    border-radius: 200px;

    // border: 1px solid darken(#eee, 4%);
    // border-top: 1px solid darken(#eee, 0%);
    // border-bottom: 1px solid darken(#eee, 8%);
    // border-right: 1px solid darken(#eee, 4%);
    // box-shadow: 1px 1px 3px 1px darken(#f7ece2, 0%);
  }

  .ruby-img {
    position: relative;
    left: -24px;
    width: 26px;
  }
  .shadow-img {
    position: relative;
    left: 0px;
    width: 27px;
    opacity: 0.4;
  }

  .ruby-count {
    margin-left: -29px;
    padding-left: 0px;
    padding-top: 10px;
    font-size: 12.5pt;
    font-weight: 600;
    color: #111;
    opacity: 0.8;
  }
}

.ToastContent {
  .message {
    font-size: 10.5pt;
    font-weight: 400;
    text-align: left;

    .image-phase {
      height: 14px;
      align-self: center;
    }

    .level-phase {
      font-weight: 600;
      color: #111;
    }
  
    .Impact-phase {
      font-weight: 600;
      color: darken(#ff9f2e, 5%);
    }
    .Growth-phase {
      font-weight: 600;
      color: darken(#13c828, 5%);
    }
    .Joy-phase {
      font-weight: 600;
      color: darken(#cb4ce5, 2%);
    }
  }

  .earned {
    display: flex;
    padding-top: 10px;

    .earned-label {
      //margin-left: auto;
      padding: 5px 6px 0 0;
      font-size: 9.5pt;
      color: #777;
      text-transform: uppercase;
    }

    .earned-items {
      margin-left: auto;
      display: flex;
    }

    .earned-item {
      display: flex;
      margin: 0 2px;
    }
    .earned-item:first-child {
      margin-left: auto !important;
    }

    .earned-number {
      margin-left: auto;
      padding: 3px 3px 0 0 ;
      font-size: 11.5pt;
      color: #777;
    }

    .earned-ruby-img {
      width: 18px;
      align-self: center;
      margin-left: 4px;
      margin-right: 8px;
    }

    .earned-coin-img {
      width: 22px;
      align-self: center;
      margin-left: -1px;  
    }

    .earned-rank {
      font-size: 10.5pt;
      font-weight: 600;
    }
    .earned-rank-impact {
      color: #ff9f2e;
    }
    .earned-rank-growth {
      color: #13c828;
    }
    .earned-rank-joy {
      color: #cb4ce5;
    }
  }
}

.Wizard-placeholder {
  width: 100%;
  height: 0px;
  //background: #111;
  //display: none;
}

.WizardContent {
  padding: 16px;
  width: 244px;
  min-height: 200px;

  .intro {
    font-size: 13.5pt;
    font-weight: 400;
    line-height:1.25em;

    p {
      padding: 0 0 0.5em 0;
    }
  }

  .next-button {
    display: inline-block;
    background: #fff;
    margin-top: 12px;
    border-radius: 4px;
    padding: 0.75em 1.25em;
    font-size: 11.5pt;
    font-weight: 500;
    color: #111;
  }
}

.MobilePrompt {
  display: none;

  .laptop-img {
    width: 80px;
    opacity: 0.9;

    margin-top: 30px;
  }

  .heading {
    padding: 20px 20px;
    font-size: 15pt;
    color: darken(#ffa26f, 30%);
  }

  .more {
    padding: 0 20px;
    font-size: 10.5pt;
    line-height: 1.3em;
  }
}

@media (max-width: 780px) {
  .ProjectsScreen {
    display: none;
  }
  
  .MobilePrompt {
    display: block;
    width: 92%;
    //height: 540px;
    background-color: #fff;
    margin: 20px 0px;
    margin-left: 4%;
    margin-bottom: 0;
    border: 1px solid darken(#eee, 4%);
    border-top: 1px solid darken(#eee, 0%);
    border-bottom: 1px solid darken(#eee, 8%);
    border-right: 1px solid darken(#eee, 4%);
    // border-left: 1px solid darken(#f7ece2, 2%);
    // border-top: 1px solid darken(#f7ece2, 2%);
    border-radius: 3px;
    padding: 0px;
    padding-bottom: 22px;
    //padding-top: 4px;
    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 2%);
    box-shadow: 1px 2px 3px 1px darken(#f7ece2, 4%);
    border-radius: 8px;
    //box-shadow: 0px 2px 3px 1px #eee;

    .logo {
      background-color: transparent !important;
      padding: 12px 20px !important;

    }
    .logo-img {
        width: 76px;
        opacity: 1;
    }

    .settings {
      display: none;
    }
  }
}