.ProjectMenu {
    padding: 12px;

    .menu-img {
        width: 20px;
        opacity: 1;
    }

    .dropmenu-container {
        position: relative;
        top: 8px;
        left: -40px;
    }

    .dropmenu {
        position: absolute;
        width: 160px;
        background: #fff;
        box-shadow: rgba(15, 15, 15, .05) 0px 0px 0px 1px, rgba(15, 15, 15, .1) 0px 3px 6px, rgba(15, 15, 15, .2) 0px 9px 24px;
        border-radius: 4px;
        padding: 4px;

        .item {
            display: flex;
            padding: 8px 8px;
            border-bottom: 0px solid #eee;
            border-radius: 4px;

            .icon-img {
                width: 19px;
            }
            .label {
                padding-left: 8px;
                font-size: 11.5pt;
                color: #111;
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
            }
            .label-confirm-trash {
                padding-left: 24px;
                color: #ef3b3b;
            }
        }
        .item:last-child {
            border-bottom: 0 !important;
        }
        .item:hover {
            background-color: lighten(#f7ece2, 2%);
        }
        .item-confirm-trash:hover {
            background-color: lighten(#ef3b3b, 38%);
        }
    }
}

.ProjectMenu:hover {
    .menu-img {
        opacity: 0.5;
    }
}

.ProjectMenu.is-open {
    .menu-img {
        opacity: 1.0 !important;
    }  
}