.ThoughtOption {
    display: flex;
    height: 44px;
    transition: opacity 1s, height 0.35s;
    transition-timing-function: ease-out;

    .bars-container {
        position: absolute;
        cursor: pointer;
    }
    .empty-bar {
        top: 0px;
        left: 0;
        position: relative;
        display: flex;
        height: 18px;
        margin: 3px 0px;
        margin-left: -4px;
        padding: 9px 16px;
        padding-top: 7px;
        background-color: #fff;
        border: 2px solid #e3e3e3;
        border-radius: 100px;
    }
    .filled-bar {
        top: -41px;
        left: 0;
        width: 50%;
        position: relative;
        display: flex;
        height: 18px;
        margin: 3px 0px;
        margin-left: -4px;
        padding: 9px 7%;
        padding-top: 7px;
        background-color: #fffaf4;
        border: 2px solid darken(#eee, 5%);
        background-color: lighten(#ddd, 2%);
        border-radius: 100px;
        transition: 1.5s width, 0.5s opacity;
    }

    .label-icon-img {
        width: 17px;
        padding-right: 4px;
        transition: 0.25s margin, 1s opacity;
        transition-delay: 0.75s;
    }

    .label {
        top: -80px;
        left: 0;
        position: relative;
        display: flex;
        margin: 3px 0px;
        margin-left: -4px;
        padding: 9px 16px;
        padding-top: 7px;
        font-size: 10pt;
        font-weight: 500;
        text-align: left;
        color: lighten(#22B14C, 4%);
        color: #111;

        // border: 2px solid lighten(#22B14C, 44%);
        // border-radius: 40px;
        // background-color: lighten(#22B14C, 56%);

        // background-color: #fff;
        // border: 2px solid #e3e3e3;
    }
}