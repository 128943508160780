.DonationDrawer {
    //display: none;  
    position: fixed;
    width: 744px;
    min-width: 744px;
    top: 0;
    left: 0px;
    height: 100%;
    background: #fff;
    //box-shadow: 0px 2px 4px 1px #ccc;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    background: rgb(238,191,248);
background: linear-gradient(0deg, rgba(238,191,248,1) 0%, rgba(255,217,173,1) 27%, rgba(255,217,173,1) 60%, rgba(255,159,46,1) 100%);

background: rgb(238,191,248);
background: linear-gradient(0deg, #fff 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, #c6ffcb 100%);

    overflow: scroll;
    padding: 0 20px;

    .big-heading {
        padding: 0.5em;
        padding-top: 0;
        font-size: 35pt;
        font-weight: 700;
        color: #111;
        text-align: center;
        letter-spacing: -1px;
    }
    .small-heading {
        padding: 0.5em 1.5em;
        padding-top: 0;
        font-size: 19pt;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.5px;
        color: darken(#f3a72f, 16%);
    }
    .small-more {
        font-size: 10.5pt;
        color: darken(#f3a72f, 16%);
    }
    .big-subheading {
        padding: 1em;
        padding-top: 0px;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .your-total {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;

        .label {
            padding: 0.25em;
            font-size: 14.5pt;
            color: #111;
        }
        .amount {
            padding: 0.25em;
            font-size: 14.5pt;
            font-weight: 600;
            color: #888;
        }
    }

    .rewards-subheading {
        padding: 2em 0 0 0;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .charities {
        padding: 40px 20px;
        padding-top: 12px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .more-label {
        padding-bottom: 80px;
        font-size: 15pt;
        font-weight: 500;
        text-align: center;
        color: #888;
    }
}


.close-button {
    display: flex;
    padding-top: 8px;
    .icon-img {
        margin-left: auto;
        opacity: 0.3;
        padding: 12px 4px 12px 12px;
        width: 44px;
    }
    .icon-img:hover {
        opacity: 0.7;
    }
}