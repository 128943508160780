.HealthOption {
    display: flex;
    width: 108px;
    height: 71px;
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 12px;
    padding-right: 30px;
    padding-bottom: 8px;

    align-items: center;
    justify-content: center;

    //transform: scale(0.95);

    .icon {
        min-width: 50px;
        padding: 13px 0 0 16px;

        .icon-img {
            width: 50px;
        }
    }
    .content {
        padding: 6px 0px 0 6px;
    }
    
    .health-label {
        font-size: 12.5pt;
        font-weight: 500;
        color: #111;
        text-align: left;
    }

    .health-since {
        display: none;
        font-size: 10pt;
        font-weight: 400;
        color: #111;
        text-align: left;
    }
}

.HealthOption.is-selected.end {
    background-color: lighten(#ffd9ad, 12%);
}
.HealthOption.is-selected.mid {
    background-color: lighten(#ffe577, 18%);
}
.HealthOption.is-selected.early {
    background-color: lighten(#97eda1, 16%);
}

.NewProjectCard {
    .HealthOption {
        width: 98px;
    }
}