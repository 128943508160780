.Charity {
    width: 260px;
    min-width: 260px;
    margin: 20px;
    text-align: left;

    .hero-img {
        width: 100%;
        border-radius: 20px;
    }

    .mast {
        display: flex;
    }

    .logo-img {
        width: 60px;
        min-width: 60px;
        border-radius: 100px;
        margin-top: -36px;
        margin-left: 20px;
    }
    
    .label {
        margin-left: auto;
        padding: 4px 16px 2px 2px;
        font-size: 9.5pt;
        font-weight: 500;
        text-align: right;
    }

    .donate {
        display: flex;
        justify-content: center;
        padding: 0 20px;

        .coin {
            padding-right: 8px;

            .ruby-img {
                position: relative;
                left: -26px;
                width: 24px;
              }
              .shadow-img {
                position: relative;
                left: 0px;
                width: 24px;
                opacity: 0.7;
              }
              .amount {
                position: relative;
                top: -26px;
                left: 28px;
                width: 24px;
                color: #111;
            }
        }
    }
}

.donat {
    //margin-left: auto;
    padding: 4px;
    padding-right: 16px;
    font-weight: 700;
    color: #111;
}
