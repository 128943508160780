.SessionCard {
    margin: 0px 22px 8px 22px;
    margin-left: 22px;
    margin-right: 0px;
    border: 1px solid #ccc;
    border-bottom: 1px solid #999;
    // border: 1px solid #aaa;
    // border-top: 1px solid #ccc;
    // border-top: 1px solid #ccc;
    // border-left: 1px solid #ccc;
    border-radius: 7px;
    padding: 0px 0px;

    box-sizing: border-box;
    padding: 0px 0;

    margin-left: 26px;
    margin-right: 6px;

    border: #fff;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    //margin-left: 32px;
    //margin-right: 20px;

    zoom: 0.95;

    .pending-content {
        display: flex;
        padding: 0px 16px;
        padding-left: 19px;

        padding-right: 0px;
    }
    .select-thought-content {
        padding-left: 16px;
        transition: 0.5s;
    }
    .completed-content {
        display: flex;
        padding: 0px 16px;
        //padding-left: 106px;
    }

    .time-content {
        display: flex;
    }

    .time-label {
        padding: 12px 0;
        text-align: left;
        font-size: 12pt;
        font-weight: 500;
        color: #aaa;
        font-style: italic;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    .time-box {
        width: 10px;
        height: 10px;
        margin: 15px 8px 0 0;
        //border: 1px solid darken(#FFFCF9, 28%);
        //border: 1px solid #555;
        //border: 1px solid #ccc;
        border-radius: 3px;

        border: 2px solid darken(#ffd9ad, 15%);
        //background: lighten(#ffd9ad, 10%);
        //border: 2px solid darken(#78e184, 3%);
    }

    .stretch-buttons-position {
        position: relative;
        height: 1px;
        width: 1px;
    }
    .stretch-buttons {
        margin-left: auto;
        padding-right: 0px;
        display: flex;
        transform: scale(1);
        opacity: 0;
        transition: all .2s;

        position: absolute;
        margin-left: 25px;
        margin-top: -9px;
        z-index: 100;

        background-color: #fff;
        padding: 0px 12px;
        border-radius: 20px;

        padding: 0;

        border: 1px solid darken(#eee, 4%);
        border-top: 1px solid darken(#eee, 0%);
        border-bottom: 1px solid darken(#eee, 8%);
        border-right: 1px solid darken(#eee, 4%);
        box-shadow: 1px 2px 3px 1px darken(#f7ece2, 0%);
    }
    .stretch-action-label {
        margin-left: auto;
        padding-right: 0px;
        transform: scale(1);
        opacity: 0;
        transition: all .2s;

        position: absolute;
        margin-left: 222px;
        margin-top: 0px;
        z-index: 50;

        width: 86px;
        //background-color: #fff;
        background-color: darken(#f7ece2, 10%);
        background-color: darken(#ff9f2e, 4%);
        background-color: #fff;
        background: lighten(#ffd9ad, 12.5%);
        //background-color: #fff;
        border-radius: 0 40px 40px 0;
        //background-color: #fff;
        //background-color: #fff;
        padding: 4px;
        //border-radius: 20px;
        font-size: 11.5pt;
        font-weight: 500;
        //font-style: italic;
        text-align: center;
        color: darken(#ff9f2e, 18%);
        //color: #fff;

        // border: 1px solid darken(#eee, 4%);
        // border-top: 1px solid darken(#eee, 0%);
        // border-bottom: 1px solid darken(#eee, 8%);
        // border-right: 1px solid darken(#eee, 4%);
        // box-shadow: 1px 2px 3px 1px darken(#f7ece2, 0%);

        display: flex;

        .stretch-action-difficulty {
            //font-style: normal;
            //color: darken(#ff9f2e, 4%);
            font-weight: 700;
        }

        .number {
            padding: 4px 4px 5px 15px;
            font-size: 11pt;
            font-weight: 400;
        }
        .label {
            font-size: 8pt;
            font-weight: 400;
            padding: 7px 4px 4px 1px;
            //letter-spacing: -.5px;
            //text-transform: uppercase;
        }
    }

    .stretch-button {
        display: none;

        height: 16px;
        margin: 8px 0;
        border: 0px solid #fff;
        border-radius: 20px;
        padding: 8px 16px 20px 16px;
        cursor: pointer;

        margin: 0;
        height: 24px;

        .stretch-img {
            //display: none;
            width: 25px;
            margin-top: 10px;
            margin-left: 2px;
            align-self: center;
            align-self: center;
        }
        .label {
            display: none;
            padding: 5px 0 0 5px;
            font-size: 11.5pt;
            font-weight: 600;
            color: darken(#ffb661, 16%);
        }

        .impact-start-img {
            width: 13px;
            margin: 11px 0 0 5px;
            padding: 0 6px;
            align-self: center;
        }
        .growth-start-img {
            width: 14px;
            margin: 11px 0 0 1px;
            padding: 0 6px;
            align-self: center;
        }
        .joy-start-img {
            width: 14px;
            margin: 13px 0 0 2px;
            padding: 0 6px;
            align-self: center;
        }
    }
    .sprint-button {
        .stretch-img {
            width: 23.5px;
            margin-left: 0px !important;
        }
    }
    .standard-button {
        //padding: 3px 8px 4px 8px !important;
        //padding: 4px 12px;
        margin-top: 6px;
        margin-left: 8px;
        padding-left: 16px;
        padding-right: 16px;
        height: 12px;

        .stretch-img {
            margin-top: 4px;
            margin-left: 0;
            width: 19px;
        }

        .standard-circle {
            width: 6px;
            height: 6px;
            border-radius: 100px;
            border: 2px solid darken(#97eda1, 11%);
            margin-top: 7px;
            margin-left: 2px;
        }
    }
    .standard-button:hover {
        //border: 1px solid #fff !important;

        .standard-circle {
            // width: 8px;
            // height: 8px;
            border-radius: 100px;
            border: 2px solid darken(#97eda1, 26%);
            //
        }
    }

    .coin-content {
        display: flex;
        padding-left: 0px;
        margin-right: 18px !important;

        .time-label {
            color: #111 !important;
            padding-right: 32px !important;
            margin-right: 32px !important;
        }
    }
    .coin-icon {
        margin: 11px 1px 0 0px;
    }
    .coin-icon-img {
        width: 20px;
    }

    .plus-label {
        padding: 12px 0 0 0;
        color: #ff9f2e;
        font-weight: 700;
    }
    .arrow-icon {
        padding: 0 6px;
    }
    .arrow-icon-img {
        margin-top: 16px;
        width: 18px;
    }

    .coin-used-content {
        //margin-left: auto;
        .coin-used-img {
            width: 15px;
            margin-top: 14px;
            //opacity: 0.75;
            //opacity: 0.5;
            //opacity: 0;
            display: none;
        }

        .coin-used-label {
            padding-left: 8px;
            padding-top: 14px;
            font-size: 10.5pt;
            font-weight: 500 !important;
            //color: #999 !important;
            display: none;
        }
    }

    .coin-congrats-content {
        margin-left: auto;
        margin-right: 8px;
        display: flex;

        .flex-img {
            width: 20px;
            margin-left: 12px;
            align-self: center;
        }
        .minutes-label {
            padding: 12px 0;
            text-align: left;
            font-size: 12pt;
            font-weight: 500;
        }
        .encouragement-label {
            padding: 12px 4px;
            padding-top: 15px;
            text-align: left;
            font-size: 9.5pt;
            font-weight: 600;
            color: #111;
            //text-transform: uppercase;
        }
    }
}

.SessionCard.ShowHoverButtons {
    .stretch-buttons {
        opacity: 1;
        transform: scale(1.25);
    }
    .stretch-action-label {
        opacity: 1;
        transform: scale(1.25);
    }
    .stretch-button {
        display: flex;
    }
}

.SessionCard.InProgress {
    .time-box {
        margin-left: 19px !important;
    }
}

.TimerWidget {
    display: flex;
    padding: 0;
    margin: 0;
    transition: 0.5s;

    .count {
        padding: 12px 2px;
    }
    .count-completed { color: #1dce1d; font-weight: 600 }

    .completed-label {
        padding: 14px 0 12.5px 2px;
        font-size: 10.5pt;
    }

    .done-button {
        padding: 10px 24px;
        margin-left: auto;
        background-color: #1dce1d;
        border: 1px solid #fff;
        border-top: 0;
        border-right: 0;
        color: #fff;
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
        transition: .7s;
    }
    .done-button:hover {
        background-color: lighten(#1dce1d, 5%);
    }

    .confetti-container-left {
        position: relative;
        margin-left: auto;
        top: 20px;
        left: 120px;
    }
    .confetti-container-right {
        position: relative;
        top: 20px;
        left: -20px;
    }
}

.SessionCard.expanded {
    //height: 200px;
}
.SessionCard.Completed {
    background: lighten(#c0f3cf, 13%);
    background: #fff;
    //margin: 7px 22px;
    border: 1px solid darken(#c0f3cf, 2%);
    border: 0;
    //border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    margin-left: 28px;
    margin-right: 6px;
    //border: 1px solid #fff;
    padding-left: 0 !important;

    //margin-left: 284px;
    //margin-left: 0px;
    //margin-right: 40px;

    .completed-content {
        //padding-left: 0px !important;
        opacity: 1;
        padding-left: 7px !important;
        padding-left: 18px !important;
    }

    .completed-border {
        //opacity: 0.0;
        opacity: 0.5;
        width: 100%;
        height: 1px;
        background: rgba(183,241,200,1);
        background: #ffe3b7;
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(183,241,200,1) 50%, rgba(183,241,200,1) 95%, rgba(255,255,255,1) 100%);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(220,220,200,1) 50%, rgba(220,220,220,1) 95%, rgba(255,255,255,1) 100%);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, #bbb 50%, #bbb 88%, rgba(255,255,255,1) 100%);
    //background: #ccc;
    //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,234,209,1) 10%, rgba(255,234,209,1) 90%, rgba(255,255,255,1) 100%);
    //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,234,209,1) 10%, rgba(255,234,209,1) 49%, rgba(255,192,93,1) 74%, rgba(255,234,209,1) 90%, rgba(255,255,255,1) 100%);
    //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,234,209,1) 7%, rgba(255,219,163,1) 49%, rgba(255,192,93,1) 74%, rgba(255,234,209,1) 94%, rgba(255,255,255,1) 100%);
    //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,234,209,1) 7%, rgba(255,234,209,1) 33%, rgba(255,219,163,1) 53%, rgba(255,192,93,1) 74%, rgba(255,234,209,1) 94%, rgba(255,255,255,1) 100%);
    //background: linear-gradient(270deg, rgba(255,234,209,1) 7%, rgba(255,234,209,1) 33%, rgba(255,219,163,1) 53%, rgba(255,192,93,1) 74%, rgba(255,234,209,1) 94%);
    //background: linear-gradient(270deg, rgba(255,234,209,1) 7%, rgba(255,234,209,1) 33%, rgba(255,219,163,1) 62%, rgba(255,192,93,1) 81%, rgba(255,234,209,1) 94%);
        //background: #ffd085;
        //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,208,133,1) 9%, rgba(255,208,133,1) 91%, rgba(255,255,255,1) 100%);
        // background: darken(#ffedd1, 9%);
        //background: #bbb;
    }
    .completed-border-first {
        background: #fff !important;
    }

    //background: #fff;
    //border: 1px solid #fff;

    .check-img {
        margin: 14px 6px 0 -4px;
        width: 16px;
        height: 16px;
    }

    .time-label {
        font-weight: 500;
        color: darken(#25c253, 0%);
        color: #888;
        padding: 12px 0;
        //text-decoration: line-through;
    }

    .strike {
        color: #888; text-decoration: line-through;
    }
    .strike-inner {
        color: #888;
    }
}

.SessionCard.NewSessionCard {
    border: 1px solid #ccc;

    .time-label {
        color: #aaa;
    }
}

.SessionCard:hover {
    cursor: pointer;
    //border-bottom: 1px solid #eee;

    .pending-content {

        .time-box {
            //opacity: 0.8;
        }
    }
}

.SessionCard.Impact:hover {
    .pending-content {
        .time-label {
            color: darken(#ffb661, 30%) !important;
        }
    }

    .stretch-button:hover {
        //border: 1px solid lighten(#ffd9ad, 14%);
        background: lighten(#ffd9ad, 12.5%);

        .label {
            color: darken(#ffb661, 26%) !important;
        }
    }

    .standard-circle {
        border: 2px solid darken(#ffb661, 0%);  
    }

    .standard-button:hover {
        .standard-circle {
            border: 2px solid darken(#ffb661, 12%);
        }
    }
}
.SessionCard.Growth:hover {
    .pending-content {
        .time-label {
            color: darken(#6bde79, 30%) !important;
        }
    }

    .stretch-action-label {
        margin-left: 158px;
        background: lighten(#6bde79, 32%);

        color: darken(#6bde79, 24%);
    }

    
    .stretch-button:hover {
        //border: 1px solid lighten(#ffd9ad, 14%);
        background: lighten(#6bde79, 32%);

        .label {
            color: darken(#97eda1, 26%) !important;
        }
    }

    .standard-circle {
        border: 2px solid darken(#97eda1, 0%);  
    }

    .standard-button:hover {
        .standard-circle {
            border: 2px solid darken(#97eda1, 12%);
        }
    }
}
.SessionCard.Joy:hover {
    
    .stretch-action-label {
        margin-left: 158px;
        background: lighten(#cb4ce5, 38%);

        color: darken(#c970db, 4%);
    }

    .stretch-button:hover {
        //border: 1px solid lighten(#ffd9ad, 14%);
        background: lighten(#cb4ce5, 38%);

        .label {
            color: darken(#eebff8, 16%) !important;
        }
    }

    .standard-circle {
        border: 2px solid darken(#eebff8, 0%);  
    }

    .standard-button:hover {
        .standard-circle {
            border: 2px solid darken(#eebff8, 2%);
        }
    }
}

.SessionCard.active {
    border-bottom: 1px solid darken(#ffe5c7, 8%);
    //border: 1px solid #fffaf4;
    background: darken(#fffaf4, 1%);
    border-radius: 12px 12px 0 0;
}
.SessionCard.Growth.active {
    border-bottom: 1px solid darken(#98efa8, 4%);
    //border: 1px solid #fffaf4;
    background: darken(#f4fff5, 1%); 

    .plus-label {
        color: darken(#98efa8, 28%);
    }
}

.SessionCard.Impact {
    .coin-used-label {
        color: darken(#ffe5c7, 28%);
        font-weight: 500;
    }
}
.SessionCard.Growth {
    .coin-used-label {
        color: darken(#98efa8, 28%);
        font-weight: 500;
    }
}

.select-thought-completed-content {
    display: flex;
    align-items: flex-start;

    .check-img {
        margin: 15px 4px 0 -2px !important;
        width: 16px !important;
        height: 16px !important;
    }

    .time-label {
        color: #25c253;
        padding: 12px 0;
    }
}

.SessionCard.Growth {
    .time-box {
        border: 2px solid darken(#78e184, 3%);
    }
}
.SessionCard.Joy {
    .time-box {
        border: 2px solid darken(#eebff8, 3%);
    }
}

.SessionCard.BonusCard {
    //border-bottom: 0px !important;
    //margin-bottom: 0;

    border-bottom: 1px solid darken(#f9e6ba, 6%) !important;
    border-bottom: 1px solid #ccc !important;

    .bonus-box {
    }
    .bonus-img {
        margin: 12px 8px 0 -6px;
        width: 22px;
    }
}

.ProjectCard.done-today.bonus-is-dropped { 
    .SessionCard.Completed {
        float: left;
        width: 38%;
        //margin-bottom: 0 !important;
        background: transparent !important;

        .completed-border {
           opacity: 0.8 !important;
        }
    }
}

.time-bonus-max {
    padding: 0 0 0 0.15em;
    //font-size: 10pt;
    color: darken(#f9e6ba, 22%);
}

.time-label-great {
    font-style: normal !important;
    font-size: 13pt !important;
    color: #333 !important;
}

.SuggestedEncourage {
    display: none;

    .position {
        position: relative;
    }

    .fill {
        position: absolute;
        top: 1px;
        left: 160px;
        width: 220px;
        //height: 40px;
        border-radius: 0 0 8px 8px;
        background-color: lighten(#ffeab0, 8%);
    }

    .content {
        .message {
            padding: 6px 12px;
            font-weight: 500;
            color: #333;
            font-size: 10.5pt;
            text-align: left;
        }

        .user {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            background-color: lighten(#ffeab0, 2%);
            padding: 4px 12px;
            border-radius: 0 0 12px 12px;

            .name {
                padding-right: 2px;
                font-weight: 700;
                color: #333;
                font-size: 10pt;
                text-align: left;
            }

            .ranks {
                display: flex;

                .rank {
                    margin-left: 2px;
                    border-radius: 20px;
                    background-color: lighten(#cb4ce5, 12%);
                    padding: 1px 6px;
                    font-size: 9pt;
                    color: #fff;
                    font-weight: 500;

                }
                .rank-impact {
                    background-color: lighten(#ff9f2e, 12%);
                }
                .rank-growth {
                    background-color: darken(#6ce37a, 4%);
                }
            }
        }
    }
}

.session-tab-total {
    padding: 13px 0 0 2px;
    font-style: italic;
    font-weight: 500;
    font-size: 11.5pt;
    color: darken(#ff9f2e, 2%);
}

.session-tab, .session-tab-new {
    background-color: lighten(#ff9f2e, 25%);
    margin-top: 3px;
    margin-right: 2px;
    height: 18px;
    
    font-size: 10.5pt;
    border-radius: 20px;

    border: 2px solid lighten(#22B14C, 46%);
    border-radius: 40px;
    background-color: lighten(#22B14C, 57.5%);
    padding: 7px 12px;
    font-size: 10.5pt;
    font-weight: 500;
    text-align: left;
    color: lighten(#22B14C, 4%);
    color: #111;

    border: 2px solid lighten(#ffd9ad, 6%);
    background-color: lighten(#ffd9ad, 15%);

    font-size: 11.5pt;
    //font-style: italic;

    margin-bottom: 5px;

    // border: 2px solid #fff;
    // background-color: #fff;
    // font-size: 11.5pt;
    // padding-left: 0;
}
.session-tab-condensed {
    //letter-spacing: -0.25px;
    padding: 7px 10px !important;
    //font-size: 11pt;
}

.session-tab {
    //min-width: 80px;
    text-align: center;

    .icon-img {
        width: 16px;
        padding-right: 2px;
        opacity: 0.5;
    }
}

.session-person {
    .icon-img {
        margin: 7px 0 0 2px;
        width: 30px;
        //opacity: 0.85;
    }
}

.session-gem {
    .icon-img {
        margin: 11px 0 0 2px;
        width: 20px;
        //opacity: 0.85;
    }
}

.session-suggest-container {
    display: flex;

    //background: linear-gradient(90deg, rgba(240,202,248,1) 0%, rgba(255,255,255,1) 49%, rgba(240,202,248,1) 100%);
    border-radius: 100px;
    background-color: lighten(#f0caf8, 4%);
    padding: 2px;
    height: 34px;

    margin-top: 2px;
}

.session-suggest {
    border: 0px solid lighten(#ffc332, 28%) !important;
    color: darken(#d46ce9, 0%) !important;
    background-color: #fff !important;
    font-style: normal !important;

    display: flex;
    padding-left: 14px;

    margin: 0;
    padding: 6px 12px;

    .icon-img {
        margin-left: 4px;
        width: 24px;
        align-self: center;
    }
    .label {
        margin-left: 0px;
        padding-left: 0px;
        font-style: normal !important;
    }
}
.session-suggest-note {
    padding-left: 2px;
    border: 2px solid #fff !important;
    font-style: italic !important;
    //color: darken(#ffc332, 1%) !important;
}

.session-tab-new {
    font-style: italic;

    border: 2px solid #eee;
    background-color: #fff;
    color: #777;
    // margin-left: 0;
    // padding-left: 0;
    // padding-right: 3px;
}

.SessionCard.Growth {
    .session-tab {
        border: 2px solid lighten(#22B14C, 46%);
        background-color: lighten(#22B14C, 57.5%);
    }
}
.SessionCard.Joy {
    .session-tab {
    border: 2px solid lighten(#eab2f6, 9%);
    background-color: lighten(#eab2f6, 16%);
    }
}
.session-tab-joy {
    border: 2px solid lighten(#eab2f6, 9%);
    background-color: lighten(#eab2f6, 16%);
}
.session-tab-growth {
    border: 2px solid lighten(#22B14C, 46%);
    background-color: lighten(#22B14C, 57.5%);
}